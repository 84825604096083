import React, { useState, useEffect } from 'react';
import { Alert, Box, Typography, Grid, Snackbar, Paper } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from '../../styles/theme';
import ConditionsList from './ConditionsList';
import ICDCodeSearch from './ICDCodeSearch';
import FinalizedCodes from './FinalizedCodes';

const CompletedCodesBuilder = ({ chart, highlightedText, handleCodeClick }) => {
    const codedCharts = chart?.coded_charts || [];

    // Separate AI and Human CodedCharts
    const aiCodedChart = codedCharts.find(codedChart => codedChart.source === 'AI') || {};
    const humanCodedChart = codedCharts.find(codedChart => codedChart.source === 'Human') || null;

    const [completedCodes, setCompletedCodes] = useState([]);
    const [userSelectedCodes, setUserSelectedCodes] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);

    // Handle adding a code manually
    const handleAddCode = (code) => {
        setUserSelectedCodes((prev) => [
            ...prev,
            {
                ...code,
                user_added: true
            }
        ]);
    };

    useEffect(() => {
        // Populate confirmed codes for FinalizedCodes if there is a human coded chart
        if (humanCodedChart) {
            const confirmedCodes = humanCodedChart.coded_chart_code_references || [];

            const formattedCompletedCodes = confirmedCodes.map(code => ({
                ...code.icd_code,
                is_recommended: code.is_recommended
            }));
            setCompletedCodes(formattedCompletedCodes);
        } else {
            setCompletedCodes([]);
        }
    }, [humanCodedChart]);

    // Group AI Identified Conditions
    const groupAICodesByCondition = (codedChart) => {
        if (!codedChart.coded_chart_code_references) return [];

        return codedChart.coded_chart_code_references.reduce((acc, ref) => {
            const conditionName = ref.condition?.name || 'Unknown';
            const highlightText = ref.highlight_text || '';
            const rationale = ref.rationale || '';

            const existingGroup = acc.find(group => group.condition === conditionName);
            if (existingGroup) {
                existingGroup.codes.push(ref.icd_code);
            } else {
                acc.push({
                    condition: conditionName,
                    codes: [ref.icd_code],
                    highlight_text: highlightText,
                    rationale: rationale
                });
            }
            return acc;
        }, []);
    };

    const aiIdentifiedConditions = groupAICodesByCondition(aiCodedChart);

const handleMoveToCompleted = (code) => {
    if (!humanCodedChart?.is_processed) {
        setCompletedCodes((prev) => [...prev, {
            ...code,
            is_recommended: code.is_recommended
        }]);
    } else {
        setAlertOpen(true);
    }
};

    // Handle removing a code from finalized
const handleRemoveFromCompleted = (code) => {
    if (!chart.is_processed) {
        setCompletedCodes((prev) => prev.filter(item => item.code !== code.code));
    } else {
        setAlertOpen(true);
    }
};

    const getHighlightedContent = () => {
        const textToHighlight = highlightedText;
        if (!textToHighlight || !chart?.content) return chart?.content;

        const parts = chart.content.split(new RegExp(`(${textToHighlight})`, 'i'));
        return parts.map((part, index) =>
            part.toLowerCase() === textToHighlight.toLowerCase() ? (
                <span key={index} style={{ backgroundColor: theme.palette.highlight.main }}>{part}</span>
            ) : (
                part
            )
        );
    };

    // Drag and Drop handler
    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedCodes = Array.from(completedCodes);
        const [removed] = reorderedCodes.splice(result.source.index, 1);
        reorderedCodes.splice(result.destination.index, 0, removed);
        setCompletedCodes(reorderedCodes);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 2, height: '100%', overflow: 'hidden' }}>
                <ICDCodeSearch
                    onAddCode={handleAddCode}
                    aiIdentifiedCodes={aiCodedChart.coded_chart_code_references?.map(ref => ref.icd_code) || []}
                />

                <Grid container spacing={2} sx={{ height: 'calc(100% - 80px)' }}>
                    {/* Suggested Codes (AI) */}
                    <Grid item xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
                        <Typography variant="h6" sx={{ marginLeft: 2, marginBottom: 1 }}>Suggested Codes</Typography>
                        <Box sx={{ padding: 2, border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                            <ConditionsList
                                aiIdentifiedConditions={aiIdentifiedConditions}
                                completedCodes={completedCodes}
                                userSelectedCodes={userSelectedCodes}
                                handleMoveToCompleted={handleMoveToCompleted}
                                handleCodeClick={handleCodeClick}
                                highlightedText={highlightedText}
                            />
                        </Box>
                    </Grid>

                    {/* Finalized Codes (Human) */}
                    <Grid item xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
                        <FinalizedCodes
                            completedCodes={completedCodes}
                            handleRemoveFromCompleted={handleRemoveFromCompleted}
                            aiIdentifiedConditions={aiIdentifiedConditions || []}
                            userSelectedCodes={userSelectedCodes}
                            onDragEnd={onDragEnd}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ marginTop: 4, height: '20%' }}>
                    <Typography variant="h6">Chart Content</Typography>
                    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, height: '100%', overflowY: 'auto' }}>
                        <Typography variant="body1" component="div">
                            {getHighlightedContent()}
                        </Typography>
                    </Paper>
                </Box>

                {highlightedText && (
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            width: '400px',
                            padding: 3,
                            backgroundColor: 'white',
                            boxShadow: 3,
                            zIndex: 1000,
                            pointerEvents: 'none'
                        }}
                    >
                        <Typography variant="body" sx={{ fontStyle: 'italic', fontWeight: 'bold' }} color="textSecondary">
                            Source:
                        </Typography>
                        <Typography variant="body" sx={{ display: 'inline', backgroundColor: theme.palette.highlight.main }}>
                            {highlightedText}
                        </Typography>
                        <Typography variant="body" sx={{ fontStyle: 'italic', fontWeight: 'bold' }} color="textSecondary">
                            Rationale:
                        </Typography>
                        <Typography variant="body">
                            {aiCodedChart.coded_chart_code_references?.find(ref => ref.highlight_text === highlightedText)?.rationale || ''}
                        </Typography>
                    </Box>
                )}

                <Snackbar
                    open={alertOpen}
                    autoHideDuration={6000}
                    onClose={() => setAlertOpen(false)}
                >
                    <Alert onClose={() => setAlertOpen(false)} severity="warning" sx={{ width: '100%' }}>
                        The chart is locked. No changes can be made.
                    </Alert>
                </Snackbar>
            </Box>
        </ThemeProvider>
    );
};

export default CompletedCodesBuilder;
