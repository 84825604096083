import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Snackbar, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CodeCard from './CodeCard';
import ProcessingTimeModal from './ProcessingTimeModal';
import { useCreateOrUpdateCodedChartMutation, useFetchChartDetailsQuery } from '../../apis/chart';

const FinalizedCodes = ({
    completedCodes,
    handleRemoveFromCompleted,
    aiIdentifiedConditions,
    userSelectedCodes,
    onDragEnd,
}) => {
    const { id } = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [createOrUpdateCodedChart, { isLoading: isLoadingCodedChartMutation }] = useCreateOrUpdateCodedChartMutation();
    const { data: chart, isFetching: isFetchingChart, refetch: refetchDetail } = useFetchChartDetailsQuery(id);

    const codedCharts = chart?.coded_charts || [];
    const humanCodedChart = codedCharts.find(codedChart => codedChart.source === 'Human') || null;

    const handleFinalizeClick = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalSubmit = async (time) => {
        try {
            await createOrUpdateCodedChart({
                id: humanCodedChart?.uuid,
                chart: chart.uuid,
                source: 'Human',
                confirmed_codes: completedCodes,
                processing_time: time,
                is_processed: true,
            }).unwrap();
            refetchDetail()
            setModalOpen(false);
            setAlertMessage('Successfully finalized codes')
            setAlertSeverity('success')
        } catch (error) {
            console.error('Failed to finalize codes:', error);
            setAlertMessage('Failed to finalize codes. Please refresh the page and try again.')
            setAlertSeverity('error')
        }
    };

    const handleUnlockCodes = async () => {
        try {
            await createOrUpdateCodedChart({
                id: humanCodedChart?.uuid,
                chart: chart.uuid,
                is_processed: false,
            }).unwrap();
            refetchDetail()
            setAlertMessage('Successfully unlocked codes!')
            setAlertSeverity('success')
        } catch (error) {
            console.error('Failed to unlock codes:', error);
            setAlertMessage('Failed to unlock codes. Please refresh the page and try again.')
            setAlertSeverity('error')
        }
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 2, marginBottom: 1 }}>
                <Typography variant="h6">Finalized Codes</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={humanCodedChart?.is_processed ? handleUnlockCodes : handleFinalizeClick}
                    disabled={isLoadingCodedChartMutation || isFetchingChart}
                    startIcon={isLoadingCodedChartMutation || isFetchingChart ? (<CircularProgress size={14} />) : null}
                >
                    {humanCodedChart?.is_processed ? "Unlock" : "Finalize"}
                </Button>
                <Snackbar
                    open={!!alertMessage}
                    autoHideDuration={3000}
                    onClose={() => setAlertMessage('')}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
                >
                    <Alert onClose={() => setAlertMessage('')} severity={alertSeverity} sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="completedCodes">
                    {(provided) => (
                        <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            sx={{ margin: 2, overflowY: 'auto', height: '100%' }}
                        >
                            {completedCodes.map((code, index) => (

                                <Draggable key={code.code} draggableId={code.code} index={index}>

                                    {(provided) => (
                                        <Box
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            sx={{
                                                padding: 1,
                                            }}
                                        >
                                            <CodeCard
                                                key={code.code}
                                                code={code}
                                                action={handleRemoveFromCompleted}
                                                chevronLeft
                                                aiIdentifiedConditions={aiIdentifiedConditions}
                                                userSelectedCodes={userSelectedCodes}
                                                {...provided.dragHandleProps}
                                            />
                                        </Box>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>

            <ProcessingTimeModal
                    open={modalOpen}
                    onClose={handleModalClose}
                    onSubmit={handleModalSubmit}
            />
        </Box>
    );
};

export default FinalizedCodes;
