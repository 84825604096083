import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
    baseUrl: `${apiUrl}/api/`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const chartApiSlice = createApi({
    reducerPath: 'api',
    baseQuery,
    tagTypes: ['Chart', 'Charts', 'CodingResult', 'ICDCode'],
    endpoints: (builder) => ({
        uploadChart: builder.mutation({
            query: (formData) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return {
                    url: `upload?chartSource=${chartSource}`,
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        uploadText: builder.mutation({
            query: (formData) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return {
                    url: `upload_text?chartSource=${chartSource}`,
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        fetchCharts: builder.query({
            query: ({ page = 1, pageSize = 25, status = 'new' }) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return `charts/?page=${page}&page_size=${pageSize}&status=${status}&chartSource=${chartSource}`;
            },
            transformResponse: (response) => ({
                data: response.results,
                total: response.count,
            }),
            providesTags: (result, error, { page, pageSize, status }) =>
                result
                    ? [
                        ...result.data.map(({ id }) => ({ type: 'Chart', id })),
                        'Charts',
                    ]
                    : ['Charts'],
        }),
        deleteChart: builder.mutation({
            query: (id) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return {
                    url: `charts/${id}/?chartSource=${chartSource}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['Charts'],
        }),
        fetchChartDetails: builder.query({
            query: (id) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return `charts/${id}?chartSource=${chartSource}`;
            },
            providesTags: (result, error, id) => [{ type: 'Chart', id }],
        }),
        fetchSlowChartData: builder.query({
            query: (id) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return `charts/${id}/slow_data/?chartSource=${chartSource}`;
            },
            providesTags: (result, error, id) => [{ type: 'Chart', id }],
        }),
        fetchChartCounts: builder.query({
            query: () => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return `chart-counts/?chartSource=${chartSource}`;
            },
            transformResponse: (response) => ({
                newCount: response.new_count,
                completedCount: response.completed_count,
                blockedCount: response.blocked_count,
                deficientCount: response.deficient_count,
            }),
            providesTags: (result, error, args) => ['Charts'],
        }),
        updateChart: builder.mutation({
            query: ({ id, ...patchData }) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return {
                    url: `charts/${id}/?chartSource=${chartSource}`,
                    method: 'PATCH',
                    body: patchData,
                };
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Chart', id }, 'Charts'],
        }),
        fetchCodedChart: builder.query({
            query: ({ chartId, coderId }) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return `coded-charts/?chart=${chartId}&coder=${coderId}&chartSource=${chartSource}`;
            },
            providesTags: (result, error, { chartId, coderId }) => [
                { type: 'CodedChart', id: `${chartId}-${coderId}` },
            ],
        }),
        createOrUpdateCodedChart: builder.mutation({
            query: ({ id, chartId, ...fields }) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                const url = id
                    ? `coded-charts/${id}/?chartSource=${chartSource}`
                    : `coded-charts/?chartSource=${chartSource}`;
                const method = id ? 'PATCH' : 'POST';

                const body = {
                    chart: chartId,
                    ...fields,
                };

                return {
                    url,
                    method,
                    body,
                };
            },
            invalidatesTags: (result, error, { chartId }) => [
                { type: 'CodedChart', id: `${chartId}` },
                'Charts',
            ],
        }),
        fetchUser: builder.query({
            query: () => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return `user/?chartSource=${chartSource}`;
            },
        }),
        fetchChartSources: builder.query({
            query: () => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return `chart-sources/?chartSource=${chartSource}`;
            },
            transformResponse: (response) => response.sources,
        }),
        fetchIcdCodes: builder.query({
            query: (search) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return `icd-codes/?search=${search}&chartSource=${chartSource}`;
            },
            providesTags: (result) => {
                if (Array.isArray(result)) {
                    return [
                        ...result.map(({ code }) => ({ type: 'ICDCode', code })),
                        { type: 'ICDCode', id: 'LIST' },
                    ];
                }
                return [{ type: 'ICDCode', id: 'LIST' }];
            },
        }),
    }),
});

export const {
    useUploadChartMutation,
    useUploadTextMutation,
    useFetchChartsQuery,
    useDeleteChartMutation,
    useFetchChartDetailsQuery,
    useFetchSlowChartDataQuery,
    useFetchChartCountsQuery,
    useFetchCodedChartQuery,
    useCreateOrUpdateCodedChartMutation,
    useFetchUserQuery,
    useFetchIcdCodesQuery,
    useUpdateChartMutation,
    useFetchChartSourcesQuery,
} = chartApiSlice;
